import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
  Button,
  ButtonGroup
} from "shards-react";

const SettingsFull = ({ title }) => (
  <Card small className="mb-4">
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="p-3">
        <Row>
          <Col>
            <Form>
              <Row form>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#pvFloatVoltage">PV Float Voltage (V)</label>
                    <FormInput id="#pvFloatVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#battOverVoltage">Battery Over Voltage (V)</label>
                    <FormInput id="#battOverVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#battOverReconnect">Battery Over Reconnect (V)</label>
                    <FormInput id="#battOverReconnect" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#battUnderVoltage">Battery Under Voltage (V)</label>
                    <FormInput id="#battUnderVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#batteryUnderReconnect">Battery Under Reconnect (V)</label>
                    <FormInput id="#batteryUnderReconnect" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#battChargingCurrent">Battery Charging Current (A)</label>
                    <FormInput id="#battChargingCurrent" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#loadCurrent">Load Current (A)</label>
                    <FormInput id="#loadCurrent" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#relayOffTime">Relay Off Time (ms)</label>
                    <FormInput id="#relayOffTime" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#relayOnTime">Relay On Time (ms)</label>
                    <FormInput id="#relayOnTime" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#gridVoltageCalibration">Grid Voltage Calibration</label>
                    <FormSelect id="feInputState">
                      <option>Enabled</option>
                      <option>Disabled</option>
                    </FormSelect>
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#exportCurrent">Export Current (A)</label>
                    <FormInput id="#exportCurrent" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#importCurrent">Import Current (A)</label>
                    <FormInput id="#importCurrent" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#pvOverVoltage">PV Over Voltage (V)</label>
                    <FormInput id="#pvOverVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#pvOverVoltageReconnect">PV Over Voltage Reconnect (V)</label>
                    <FormInput id="#pvOverVoltageReconnect" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#pvLowVoltageReconnect">PV Low Voltage Reconnect (V)</label>
                    <FormInput id="#pvLowVoltageReconnect" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#pvLowVoltage">PV Low Voltage (V)</label>
                    <FormInput id="#pvLowVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#pvLowCurrent">PV Low Current (A)</label>
                    <FormInput id="#pvLowCurrent" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#mainsOverVoltage">Mains Over Voltage (V)</label>
                    <FormInput id="#mainsOverVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#mainsOverReconnectVoltage">Mains Over Reconnect Voltage (V)</label>
                    <FormInput id="#mainsOverReconnectVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#mainsUnderReconnectVoltage">Mains Under Reconnect Voltage (V)</label>
                    <FormInput id="#mainsUnderReconnectVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#mainsUnderVoltage">Mains Under Voltage (V)</label>
                    <FormInput id="#mainsUnderVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#pvBoostVoltage">PV Boost Voltage (V)</label>
                    <FormInput id="#pvBoostVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#chargingEqualizerVoltage">Charging Equalizer Voltage (V)</label>
                    <FormInput id="#chargingEqualizerVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#equalization">Equalization</label>
                    <FormSelect id="#equalization">
                      <option>Enabled</option>
                      <option>Disabled</option>
                    </FormSelect>
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#pvLimit">PV Limit (kW)</label>
                    <FormInput id="#pvLimit" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#mode">Mode</label>
                    <FormSelect id="#mode">
                      <option>Hybrid</option>
                      <option>SBG</option>
                      <option>SGB</option>
                      <option>GSB</option>
                    </FormSelect>
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#gridConnectBatteryVoltage">Grid Connect Battery Voltage (V)</label>
                    <FormInput id="#gridConnectBatteryVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#gridChargingStartBatteryVoltage">Grid Charging Start Battery Voltage (V)</label>
                    <FormInput id="#gridChargingStartBatteryVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
              <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#gridChargingStopBatteryVoltage">Grid Charging Stop Battery Voltage (V)</label>
                    <FormInput id="#gridChargingStopBatteryVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#gridDisconnectBatteryVoltage">Grid Disconnect Battery Voltage (V)</label>
                    <FormInput id="#gridDisconnectBatteryVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#gridChargerBoostVoltage">Grid Charger Boost Voltage (V)</label>
                    <FormInput id="#gridChargerBoostVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#gridChargerFloatVoltage">Grid Charger Float Voltage (V)</label>
                    <FormInput id="#gridChargerFloatVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
              <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#inverterRating">Inverter Rating (V)</label>
                    <FormInput id="#inverterRating" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#batteryType">Battery Type</label>
                    <FormSelect id="#batteryType">
                      <option>VRLA</option>
                      <option>LM</option>
                      <option>LI-ION</option>
                    </FormSelect>
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#boostToFloatTiming">Boost to Float Timing (V)</label>
                    <FormInput id="#boostToFloatTiming" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#gridChargerFloatVoltage">Grid Export</label>
                    <FormInput id="#gridChargerFloatVoltage" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
              <Col md="3" className="form-group">
                  <FormGroup>
                    <label htmlFor="#inverterRating">Grid Import</label>
                    <FormInput id="#inverterRating" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                <FormGroup>
                    <label htmlFor="#batteryCapacity">Battery Capacity</label>
                    <FormInput id="#batteryCapacity" type="number" placeholder="123" />
                  </FormGroup>
                </Col>
                <Col md="3" className="form-group">
                </Col>
                <Col md="3" className="form-group">
                </Col>
              </Row>
              <Button theme="accent" className="mr-2">Update</Button>
              <Button outline theme="accent" className="mr-2">Default</Button>
            </Form>
          </Col>
        </Row>
      </ListGroupItem>
    </ListGroup>
  </Card>
);

SettingsFull.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

SettingsFull.defaultProps = {
  title: "System Settings"
};

export default SettingsFull;
