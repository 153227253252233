import React from "react"
import { Container, Row, Col, Card, CardHeader, Cardbody, CardBody } from "shards-react"
import PageTitle from "../components/common/PageTitle";
import { Link } from 'react-router-dom';
import axios from "axios";
import { Component } from "react";

var idx;
const User = props => (
    <tr>
        <td>{idx++}</td>
        <td>{props.user.name}</td>
        <td>{props.user.role}</td>
        <td>{props.user.act?'Active':'Inactive'}</td>
        <td>{props.user.createdAt.substring(0,10)}</td>
        <td>{props.user.updatedAt.substring(0,10)}</td>
        <td>
            <Link to={"/useredit/"+props.user._id}>Edit</Link> | <a href="#" onClick={()=>{props.deleteUser(props.user._id)}}>Delete</a>            
        </td>
    </tr>
)

export default class UsersList extends Component{
    constructor(props){
        super(props);

        this.deleteUser = this.deleteUser.bind(this);
        this.state = {users:[]};

    }

    componentDidMount(){
        axios.get('http://localhost:5000/users')
            .then(response =>{
                this.setState({users: response.data})
            })
            .catch((error)=>{
                console.log(error);
            })
    }

    deleteUser(id){
        axios.delete('http://localhost:5000/users/'+id)
            .then(response =>{ console.log(response.data)});

        this.setState({
            users: this.state.users.filter(el => el._id !== id)
        })
    }

    userList() {
        idx=1;
        return this.state.users.map(currentuser =>{
            return <User user={currentuser} deleteUser={this.deleteUser} key={currentuser._id}/>;
        })
    }

    render(){
        return(
            <Container fluid className="main-content-container px-4">
        {/* Page  Header */}
        <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title="Users" subtitle="User Management" className="text-sm-left"/>
        </Row>

        {/* User Table */}
        <Row>
            <Col>
            <Card small className="mb-4">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">All Users</h6>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                    <table className="table mb-0">
                        <thead className="bg-light">
                        <tr>
                            <th scope="col" className="border-0">
                                #
                            </th>
                            <th scope="col" className="border-0">
                                Name
                            </th>
                            {/* <th scope="col" className="border-0">
                                Password
                            </th> */}
                            <th scope="col" className="border-0">
                                Role
                            </th>
                            <th scope="col" className="border-0">
                                Status
                            </th>
                            <th scope="col" className="border-0">
                                Created
                            </th>
                            <th scope="col" className="border-0">
                                Modified
                            </th>
                            <th scope="col" className="border-0">
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            { this.userList() }
                        </tbody>
                    </table>
                </CardBody>
            </Card>
            </Col>
        </Row>
    </Container>
        )
    }
}