import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
} from "shards-react";

const SystemFaults = ({ title, faults }) => (
  <Card >
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
      <div className="block-handle" />
    </CardHeader>

    <CardBody className="p-0">
      <ListGroup flush className="list-group-small">
        {faults.map((item, idx) => (          
          <div>
            {item.fault?
          <ListGroupItem className="d-flex px-3">
            <span key={idx} className="text-semibold text-fiord-blue">{item.fault}</span>
          </ListGroupItem>:null}
          </div>
        ))}
      </ListGroup>
    </CardBody>
  </Card>
);

SystemFaults.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The referral data.
   */
  faults: PropTypes.array,

};

SystemFaults.defaultProps = {
  title: "System Faults / Alerts",
  faults: [{fault:""},{fault:""},]
};

export default SystemFaults;
