import React from "react"
import { Container, Row, Col, Card, CardHeader, Cardbody, CardBody } from "shards-react"
import PageTitle from "../components/common/PageTitle";

// userList() {

// }

const Tables = () =>(

    <Container fluid className="main-content-container px-4">
        {/* Page  Header */}
        <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title="Device Types" subtitle="Product Categories" className="text-sm-left"/>
        </Row>

        {/* User Table */}
        <Row>
            <Col>
            <Card small className="mb-4">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">All Device Types</h6>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                    <table className="table mb-0">
                        <thead className="bg-light">
                        <tr>
                            <th scope="col" className="border-0">
                                #
                            </th>
                            <th scope="col" className="border-0">
                                Name
                            </th>
                            <th scope="col" className="border-0">
                                Category
                            </th>
                            <th scope="col" className="border-0">
                                Rating
                            </th>
                            <th scope="col" className="border-0">
                                Created
                            </th>
                            <th scope="col" className="border-0">
                                Modified
                            </th>
                            <th scope="col" className="border-0">
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            {/* { this.userList() } */}
                        </tbody>
                    </table>
                </CardBody>
            </Card>
            </Col>
        </Row>
    </Container>
);

export default Tables;