import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  CardFooter,
  Row,
  Col,
  FormSelect,
  ButtonGroup, Button
} from "shards-react";

const SettingSimple = ({ title, referralData }) => (
  <Card >
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
      <div className="block-handle" />
    </CardHeader>

    <CardBody className="p-0">
      <ListGroup flush className="list-group-small">
        {referralData.map((item, idx) => (
          <ListGroupItem key={idx} className="d-flex px-3">
            <span className="text-semibold text-fiord-blue">{item.title}</span>
            <span className="ml-auto text-right text-semibold text-reagent-gray">
              {item.value}
            </span>
            <ButtonGroup className="mb-0">
              <Button theme="primary">Fizz</Button>
              <Button theme="white">Buzz</Button>
              <Button theme="white">Foo</Button>
              <Button theme="white">Bar</Button>
            </ButtonGroup>
          </ListGroupItem>
        ))}
      </ListGroup>
    </CardBody>
  </Card>
);

SettingSimple.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The referral data.
   */
  referralData: PropTypes.array
};

SettingSimple.defaultProps = {
  title: "System Settings",
  referralData: [
    {
      title: "System Mode",
      value: "Import"
    },
    {
      title: "Battery Type",
      value: "On"
    },
    {
      title: "Battery Capacity",
      value: "Charging"
    }
  ]
};

export default SettingSimple;
