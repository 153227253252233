import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import BlogOverview from "./views/BlogOverview";
import UserProfileLite from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import Errors from "./views/Errors";
import ComponentsOverview from "./views/ComponentsOverview";
import Tables from "./views/Tables";
import BlogPosts from "./views/BlogPosts";
import Users from "./views/Users";
import UserEdit from "./views/UserEdit"
import Locations from "./views/Locations";
import DeviceTypes from "./views/DeviceTypes";
import Devices from "./views/Devices";
import Dashboard from "./views/Dashboard";
import DashboardAdvance from "./views/DashboardAdvance";
import Settings from "./views/Settings";
export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: "/blog-overview",
    layout: DefaultLayout,
    component: BlogOverview
  },
  {
    path: "/user-profile-lite",
    layout: DefaultLayout,
    component: UserProfileLite
  },
  {
    path: "/add-new-post",
    layout: DefaultLayout,
    component: AddNewPost
  },
  {
    path: "/errors",
    layout: DefaultLayout,
    component: Errors
  },
  {
    path: "/components-overview",
    layout: DefaultLayout,
    component: ComponentsOverview
  },
  {
    path: "/tables",
    layout: DefaultLayout,
    component: Tables
  },
  {
    path: "/blog-posts",
    layout: DefaultLayout,
    component: BlogPosts
  },
  {
    path: "/users",
    layout: DefaultLayout,
    component: Users
  },
  {
    path: "/useredit/:id",
    layout: DefaultLayout,
    component: UserEdit
  },
  {
    path: "/locations",
    layout: DefaultLayout,
    component: Locations
  },
  {
    path: "/devicetypes",
    layout: DefaultLayout,
    component: DeviceTypes
  },
  {
    path: "/devices",
    layout: DefaultLayout,
    component: Devices
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    component: Dashboard
  },
  {
    path: "/dashboard-advance",
    layout: DefaultLayout,
    component: DashboardAdvance
  },
  {
    path: "/settings",
    layout: DefaultLayout,
    component: Settings
  }
];
