import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  ListGroup,
  ListGroupItem,
  Progress,
  FormCheckbox
} from "shards-react";

const DeviceDetails = ({ deviceDetails }) => (
  <Card small className="mb-4 pt-3">
    <CardHeader className="border-bottom text-center">
      <div className="mb-3 mx-auto">
        <img
          className="rounded"
          src={deviceDetails.deviceImg}
          alt={deviceDetails.siteName}
          width="180"
        />
      </div>
      <h4 className="mb-0">{deviceDetails.name}</h4>
      <span className="text d-block mb-0">{deviceDetails.deviceName}</span>
      <span className="text-muted d-block mb-0">{deviceDetails.fwVersion}</span>
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="d-flex px-3">
        <span className="text-semibold text-fiord-blue">Connectivity Status</span>
        <span className="ml-auto text-right text-semibold text-reagent-gray">
          {deviceDetails.conStat}
        </span>
      </ListGroupItem>
      <ListGroupItem className="d-flex px-3">
        <span className="text-semibold text-fiord-blue">Last Sync</span>
        <span className="ml-auto text-right text-semibold text-reagent-gray">
          {deviceDetails.lastSync}
        </span>
      </ListGroupItem>
      <ListGroupItem className="d-flex px-3">
        <span className="text-semibold text-fiord-blue">Output Switch</span>
        <span className="ml-auto text-right text-semibold text-reagent-gray">
        <FormCheckbox toggle small checked={deviceDetails.osw}>
        {deviceDetails.osw?'ON':'OFF'}
      </FormCheckbox>
        </span>
      </ListGroupItem>
      <ListGroupItem className="p-3">
        <strong className="text-muted d-block mb-2">
          Location
        </strong>
        <div className="mb-0 mx-auto">
          <img
            class="mx-auto d-block rounded img-fluid"
            src={deviceDetails.mapImg}
          />
        </div>
      </ListGroupItem>
      <ListGroupItem className="d-flex px-3">
        <span className="text-semibold text-fiord-blue">Temperature</span>
        <span className="ml-auto text-right text-semibold text-reagent-gray">
          {deviceDetails.temp}
        </span>
      </ListGroupItem>
      <ListGroupItem className="d-flex px-3">
        <span className="text-semibold text-fiord-blue">Humidity</span>
        <span className="ml-auto text-right text-semibold text-reagent-gray">
          {deviceDetails.humi}
        </span>
      </ListGroupItem>
      <ListGroupItem className="d-flex px-3">
        <span className="text-semibold text-fiord-blue">Wind Speed / Dir</span>
        <span className="ml-auto text-right text-semibold text-reagent-gray">
          {deviceDetails.wind}
        </span>
      </ListGroupItem>
    </ListGroup>
  </Card>
);

DeviceDetails.propTypes = {
  /**
   * The user details object.
   */
  deviceDetails: PropTypes.object
};

export default DeviceDetails;
