import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
} from "shards-react";

const SystemStatus = ({ title, batt, inv, grid }) => (
  <Card >
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
      <div className="block-handle" />
    </CardHeader>

    <CardBody className="p-0">
      <ListGroup flush className="list-group-small">
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Grid Status</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
            {grid}
          </span>
        </ListGroupItem>

        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Battery Status</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
            {batt}
          </span>
        </ListGroupItem>

        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Inverter Status</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
            {inv}
          </span>
        </ListGroupItem>
      </ListGroup>
    </CardBody>
  </Card>
);

SystemStatus.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The referral data.
   */
  batt: PropTypes.string,
  inv: PropTypes.string,
  grid: PropTypes.string

};

SystemStatus.defaultProps = {
  title: "System Status",
  batt: '',
  inv: '',
  grid: ''
};

export default SystemStatus;
