import React from "react";
import PropTypes from "prop-types";
import {
  Container, Row, Col, Card, CardHeader, CardBody, Button, ButtonGroup, ListGroupItem, ListGroup,
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import SmallStats from "../components/common/SmallStats";
import TodayGraphs from "../components/blog/TodayGraphs";
import CumulativeGraphs from "../components/blog/CumulativeGraphs";
import SystemStatus from "../components/common/SystemStatus";
import SystemParameters from "../components/common/SystemParameters";
import SettingSimple from "../components/common/SettingSimple";
import RYBStats from "../components/common/RYBStats";
import SmallButtons from "../components/components-overview/SmallButtons";

import DeviceDetails from "../components/user-profile-lite/UserDetails";
import SettingsFull from "../components/common/SettingsFull";

const DeviceDashboard = ({ rYBStats, smallStats_pv, smallStats_inv, smallStats_batt }) => (
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle title="Device: Device Name" subtitle="Hi User Name" className="text-sm-left mb-3" />
    </Row>
    <Row>
      {/* Device Details */}
      <Col lg="3" md="12" sm="12" className="mb-4">
        <DeviceDetails />
      </Col>
      <Col lg="9" md="12" sm="12" className="mb-4">
        <Row >
          <Col lg="12" md="12" sm="12" className="mb-4">
            <SettingsFull/>
          </Col>
        </Row>
      </Col>
    </Row>

    {/* <Card >
      <CardHeader className="border-bottom">
        <h6 className="m-0">System Faults</h6>
        <div className="block-handle" />
      </CardHeader>
      <CardBody className="p-0">
        <table className="table mb-0">
          <thead className="bg-light">
            <tr>
              <th scope="col" className="border-0">
                Phase
              </th>
              <th scope="col" className="border-0">
                Batt UV
              </th>
              <th scope="col" className="border-0">
                Batt OV
              </th>
              <th scope="col" className="border-0">
                PV UV
              </th>
              <th scope="col" className="border-0">
                PV OV
              </th>
              <th scope="col" className="border-0">
                Grid UV
              </th>
              <th scope="col" className="border-0">
                Grid OV
              </th>
              <th scope="col" className="border-0">
                Inv UV
              </th>
              <th scope="col" className="border-0">
                Inv OV
              </th>
              <th scope="col" className="border-0">
                OP OL
              </th>
              <th scope="col" className="border-0">
                PV OL
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="col">
                Red
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK </Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK</Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK</Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK</Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK</Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK</Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK</Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK</Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK</Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK</Button>
              </th>
            </tr>
            <tr>
              <th scope="col">
                Yellow
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="warning" className="mb-2 mr-1">ER</Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="warning" className="mb-2 mr-1">ER</Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="warning" className="mb-2 mr-1">ER</Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="warning" className="mb-2 mr-1">ER</Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="warning" className="mb-2 mr-1">ER</Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="warning" className="mb-2 mr-1">ER</Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="warning" className="mb-2 mr-1">ER</Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="warning" className="mb-2 mr-1">ER</Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="warning" className="mb-2 mr-1">ER</Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="warning" className="mb-2 mr-1">ER</Button>
              </th>
            </tr>
            <tr>
              <th scope="col">
                Blue
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK </Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK </Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK </Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK </Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK </Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK </Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK </Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK </Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK </Button>
              </th>
              <th scope="col" className="border-0">
                <Button size="sm" theme="success" className="mb-2 mr-1">OK </Button>
              </th>
            </tr>
          </tbody>
        </table>
      </CardBody>
    </Card> */}
  </Container>
);

DeviceDashboard.propTypes = {
  /**
   * The small stats dataset.
   */
  rYBStats: PropTypes.array,
  smallStats_pv: PropTypes.array,
  smallStats_inv: PropTypes.array
};

DeviceDashboard.defaultProps = {
  rYBStats: [
    {
      label: "GRID VOLTAGE (V)",
      value_r: "550",
      value_y: "550",
      value_b: "550",
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        },
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        },
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    },
    {
      label: "PV VOLTAGE (V)",
      value_r: "550",
      value_y: "550",
      value_b: "550",
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        },
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        },
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    },
    {
      label: "INVERTER VOLTAGE (V)",
      value_r: "550",
      value_y: "550",
      value_b: "550",
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        },
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        },
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    },
    {
      label: "INVERTER CURRENT (A)",
      value: "182",
      percentage: "12.4",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        },
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        },
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    }
  ],
  smallStats_pv: [
    {
      label: "PV POWER (kW)",
      value: "8,147",
      percentage: "3.8%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        }
      ]
    },
    {
      label: "BATTERY VOLTAGE (V)",
      value: "8,147",
      percentage: "3.8%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        }
      ]
    },
    {
      label: "BATTERY CURRENT (A)",
      value: "29",
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    }
  ],
  smallStats_inv: [
    {
      label: "TODAY PV GEN (kWh)",
      value: "29",
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [0]
        }
      ]
    },
    {
      label: "GRID POWER (kW)",
      value: "29",
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    },
    {
      label: "INVERTER POWER (kW)",
      value: "29",
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    }
  ]
};

export default DeviceDashboard;
