import React from "react"
import { Container, Row, Col, Card, CardHeader, Cardbody, CardBody } from "shards-react"
import PageTitle from "../components/common/PageTitle";
import axios from "axios";
import { Component } from "react";

const User = props => (
    <tr>
        <td>{props.user.name}</td>
        <td>{props.user.role}</td>
        <td>{props.user.act}</td>
        <td>{props.user.createdAt}</td>
        <td>{props.user.updatedAt}</td>
        <td>
            Action here
        </td>
    </tr>
)

export default class UsersList extends Component{
    constructor(props){
        super(props);

        this.deleteUser = this.deleteUser.bind(this);
        this.state = {users:[]};

    }

    componentDidMount(){
        axios.get('http://localhost:5000/users')
            .then(response =>{
                this.setState({users: response.data})
            })
            .catch((error)=>{
                console.log(error);
            })
    }

    deleteUser(id){
        axios.delete('http://localhost:5000/users/'+id)
            .then(response =>{ console.log(response.data)});

        this.setState({
            users: this.state.users.filter(el => el._id !== id)
        })
    }

    userList() {
        return this.state.users.map(currentuser =>{
            return <User user={currentuser} deleteUser={this.deleteUser} key={currentuser._id}/>;
        })
    }

    render(){
        return(
            <Container fluid className="main-content-container px-4">
        {/* Page  Header */}
        <Row noGutters className="page-header py-4">
            <PageTitle sm="4" title="Devices" subtitle="Device Management" className="text-sm-left"/>
        </Row>

        {/* User Table */}
        <Row>
            <Col>
            <Card small className="mb-4">
                <CardHeader className="border-bottom">
                    <h6 className="m-0">All Devices</h6>
                </CardHeader>
                <CardBody className="p-0 pb-3">
                    <table className="table mb-0">
                        <thead className="bg-light">
                        <tr>
                            <th scope="col" className="border-0">
                                #
                            </th>
                            <th scope="col" className="border-0">
                                Product Serial
                            </th>
                            <th scope="col" className="border-0">
                                Device Type
                            </th>
                            <th scope="col" className="border-0">
                                Location
                            </th>
                            <th scope="col" className="border-0">
                                Created
                            </th>
                            <th scope="col" className="border-0">
                                Modified
                            </th>
                            <th scope="col" className="border-0">
                                Actions
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                            { this.userList() }
                        </tbody>
                    </table>
                </CardBody>
            </Card>
            </Col>
        </Row>
    </Container>
        )
    }


    
}


// const Tables = () =>(

    
// );

// export default Tables;