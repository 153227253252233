import React, { useState, useEffect } from "react";
import axios from 'axios';
import PropTypes from "prop-types";
import {
  Container, Row, Col, Card, CardHeader, CardBody, Button, ButtonGroup, ListGroupItem, ListGroup,
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";

import PageTitle from "../components/common/PageTitle";
import SmallStats from "../components/common/SmallStats";
import TodayGraphs from "../components/blog/TodayGraphs";
import CumulativeGraphs from "../components/blog/CumulativeGraphs";
import SystemStatus from "../components/common/SystemStatus";
import SystemFaults from "../components/common/SystemFaults";
import SystemParameters from "../components/common/SystemParameters";
import SettingSimple from "../components/common/SettingSimple";
import RYBStats from "../components/common/RYBStats";
import SmallButtons from "../components/components-overview/SmallButtons";

import DeviceDetails from "../components/common/DeviceDetails";

import NumericInput from 'react-numeric-input';

const DeviceDashboard = ( {sysStatus,sysParams,pvtPoints} ) => {  

  const [sParams, setSysParams] = useState(sysParams);
  const [sStatus, setSysStatus] = useState(sysStatus);
  const [pPoints, setPvtPoints] = useState(pvtPoints);
  
  function getData(){
    
    axios.get('http://103.11.153.46:5000/3pdata/new')
    .then(res => {
      setSysParams(res.data)

      //calculate connectivity status
      const mins =  Math.floor((Date.now() - new Date(res.data.createdAt)) / 60000);          
      sysStatus.conStat=mins<6?'Online':mins<10?'Waiting':'Offline';
      sysStatus.lastSync=res.data.createdAt;
      setSysStatus(sysStatus);

      console.log('Date Now: '+Date.now.toString+' DateCreated '+new Date(res.data.createdAt)+' tdiff: '+mins);

      // if(tdiff>5*)
    })
    .catch((error) => {
      // console.log(error);
    })
  }

  function getChartData(){

    axios.get('http://103.11.153.46:5000/3pdata/pvt/21F0012P')
    .then(res => {
      pvtPoints.datasets.data=res.data;
      setPvtPoints(pvtPoints);
      console.log(pvtPoints);
    })
    .catch((error) => {
      // console.log(error);
    })

  }

  useEffect(()=>{
    getData();
    getChartData();
    setInterval(getData,5000);
  },[]);

  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle title="Device: Device Name" subtitle="Hi User Name" className="text-sm-left mb-3" />
      </Row>
      <Row>
        {/* Device Details */}
        <Col lg="3" md="12" sm="12" className="mb-4">
          <DeviceDetails deviceDetails={sysStatus}/>
          <SystemStatus grid={sParams.grid} batt={sParams.batt} inv={sParams.inv}/>
        </Col>
        <Col lg="9" md="12" sm="12" className="mb-4">
          <Row >
            <Col lg="4" md="12" sm="12" className="mb-4">
            <Col lg="12" md="12" sm="12" className="mb-4">
                <SystemParameters sysParamData={sParams}/>
              </Col>
              <Col lg="12" md="12" sm="12" className="mb-4">
                <SystemFaults faults={[sParams.pv_uv_ch1?{fault:"PV Ch-1 Under Voltage!"}:{},
                sParams.pv_uv_ch2?{fault:"PV Ch-2 Under Voltage!"}:{},
                sParams.pv_uv_ch3?{fault:"PV Ch-3 Under Voltage!"}:{},
                sParams.pv_ov_ch1?{fault:"PV Ch-1 Over Voltage!"}:{},
                sParams.pv_ov_ch2?{fault:"PV Ch-2 Over Voltage!"}:{},
                sParams.pv_ov_ch3?{fault:"PV Ch-3 Over Voltage!"}:{},
                sParams.m_ov_r?{fault:"Mains Ph-R Over Voltage!"}:{},
                sParams.m_ov_y?{fault:"Mains Ph-Y Over Voltage!"}:{},
                sParams.m_ov_b?{fault:"Mains Ph-B Over Voltage!"}:{},
                sParams.m_uv_r?{fault:"Mains Ph-R Under Voltage!"}:{},
                sParams.m_uv_y?{fault:"Mains Ph-Y Under Voltage!"}:{},
                sParams.m_uv_b?{fault:"Mains Ph-B Under Voltage!"}:{},

                sParams.b_uv_al?{fault:"Battery Under Voltage Alarm!"}:{},
                sParams.b_uv_co?{fault:"Battery Under Volage Cut-Off"}:{},

                sParams.i_ol_r?{fault:"Inverter Ph-R Overload!"}:{},
                sParams.i_ol_y?{fault:"Inverter Ph-Y Overload!"}:{},
                sParams.i_ol_b?{fault:"Inverter Ph-B Overload!"}:{},

                sParams.i_pv_ool_ch1?{fault:"PV Ch-1 Current Out of Limit!"}:{},
                sParams.i_pv_ool_ch2?{fault:"PV Ch-2 Current Out of Limit!"}:{},
                sParams.i_pv_ool_ch3?{fault:"PV Ch-3 Current Out of Limit!"}:{},

                sParams.fan_fail?{fault:"Fan Fail!"}:{},
                sParams.batt_ov?{fault:"Battery Over Voltage!"}:{},

                sParams.i_ov_r?{fault:"Inverter Ph-R Over Voltage!"}:{},
                sParams.i_ov_y?{fault:"Inverter Ph-Y Over Voltage!"}:{},
                sParams.i_ov_b?{fault:"Inverter Ph-B Over Voltage!"}:{},
                sParams.i_uv_r?{fault:"Inverter Ph-R Under Voltage!"}:{},
                sParams.i_uv_y?{fault:"Inverter Ph-Y Under Voltage!"}:{},
                sParams.i_uv_b?{fault:"Inverter Ph-B Under Voltage!"}:{},
                sParams.tcs_f?{fault:"TCS Fail!"}:{},
                sParams.sw_off?{fault:"Switch Off!"}:{},

                ]}/>
              </Col>
            </Col>
            <Col lg="8" md="12" sm="12" className="mb-4">
              <Col className="mb-4 p-0">
                <TodayGraphs chartData={pPoints}/>
              </Col>
              <Col className="mb-4 p-0">
                <CumulativeGraphs />
              </Col>
              <Row>
                <Col lg="12" md="12" sm="12" className="mb-4">
                  {/* <SettingSimple /> */}
                  <Card >
                    <CardHeader className="border-bottom">
                      <h6 className="m-0">System Settings</h6>
                      <div className="block-handle" />
                    </CardHeader>
  
                    <CardBody className="p-0">
                      <ListGroup flush className="list-group-small">
                        <ListGroupItem className="d-flex px-3">
                          <span className="text-semibold text-fiord-blue">System Mode</span>
                          <span className="ml-auto text-right text-semibold text-reagent-gray">
                            <ButtonGroup className="mb-0">
                              <Button theme="primary">HYBRID</Button>
                              <Button theme="white">SBG</Button>
                              <Button theme="white">SGB</Button>
                              <Button theme="white">GSB</Button>
                            </ButtonGroup>
                          </span>
                        </ListGroupItem>
                        <ListGroupItem className="d-flex px-3">
                          <span className="text-semibold text-fiord-blue">Battery Type</span>
                          <span className="ml-auto text-right text-semibold text-reagent-gray">
                            <ButtonGroup className="mb-0">
                              <Button theme="primary">VRLA</Button>
                              <Button theme="white">LM</Button>
                              <Button theme="white">Li-Ion</Button>
                            </ButtonGroup>
                          </span>
                        </ListGroupItem>
                        <ListGroupItem className="d-flex px-3">
                          <span className="text-semibold text-fiord-blue">Battery Capacity</span>
                          <span className="ml-auto text-right text-semibold text-reagent-gray">
                            {/* <NumericInput min={100} max={600} value={300} step={2}/> */}
                            <InputGroup className="mb-0">
                              <FormInput value="1000" onChange={() => { }} />
                              <InputGroupAddon type="append">
                                <InputGroupText>Volts</InputGroupText>
                              </InputGroupAddon>
                            </InputGroup>
                          </span>
                        </ListGroupItem>
                        <ListGroupItem className="d-flex px-3">
                          <span className="text-semibold text-fiord-blue">Save</span>
                          <span className="ml-auto text-right text-semibold text-reagent-gray">
                            <Button outline theme="primary" className="mb-2 mr-1">
                              Save
                            </Button>
                          </span>
                        </ListGroupItem>
                      </ListGroup>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

DeviceDashboard.propTypes = {

  pvtPoints:PropTypes.object,
  sysStatus:PropTypes.object,
  // {
  //   siteName:PropTypes.string,
  //   deviceName:PropTypes.string,
  //   fwVersion:PropTypes.string,
  //   conStat:PropTypes.string,
  //   lastSync:PropTypes.string,
  //   osw:PropTypes.bool,
  //   loc_lat:PropTypes.number,
  //   loc_lon:PropTypes.number,
  //   temp:PropTypes.string,
  //   humi: PropTypes.string,
  //   wind: PropTypes.string
  // },

  sysParams: PropTypes.object
  // {
  //   // _id: PropTypes.string,
  //   sno: PropTypes.string,
  //   dt_src: PropTypes.string,
  //   g_ss: PropTypes.number,
  //   pvv_ch1: PropTypes.number,
  //   pvv_ch2: PropTypes.number,
  //   pvv_ch3: PropTypes.number,
  //   pvi_ch1: PropTypes.number,
  //   pvi_ch2: PropTypes.number,
  //   pvi_ch3: PropTypes.number,
  //   pv_kwh_t: PropTypes.number,
  //   pv_kwh_c: PropTypes.number,
  //   bv: PropTypes.number,
  //   bi: PropTypes.number,
  //   gv_r: PropTypes.number,
  //   gv_y: PropTypes.number,
  //   gv_b: PropTypes.number,
  //   gi_r: PropTypes.number,
  //   gi_y: PropTypes.number,
  //   gi_b: PropTypes.number,
  //   gf_r: PropTypes.number,
  //   gf_y: PropTypes.number,
  //   gf_b: PropTypes.number,
  //   gpf_r: PropTypes.number,
  //   gpf_y: PropTypes.number,
  //   gpf_b: PropTypes.number,
  //   gtp: PropTypes.number,
  //   i_kwh_t: PropTypes.number,
  //   e_kwh_t: PropTypes.number,
  //   im_kwh_c: PropTypes.number,
  //   e_kwh_c: PropTypes.number,
  //   iv_r: PropTypes.number,
  //   iv_y: PropTypes.number,
  //   iv_b: PropTypes.number,
  //   ii_r: PropTypes.number,
  //   ii_y: PropTypes.number,
  //   ii_b: PropTypes.number,
  //   if_r: PropTypes.number,
  //   if_y: PropTypes.number,
  //   if_b: PropTypes.number,
  //   lpf_r: PropTypes.number,
  //   lpf_y: PropTypes.number,
  //   lpf_b: PropTypes.number,
  //   ip: PropTypes.number,
  //   u_kwh_t: PropTypes.number,
  //   i_kwh_c: PropTypes.number,
  //   batt: PropTypes.string,
  //   inv: PropTypes.string,
  //   grid: PropTypes.string,
  //   pv_p_ch1: PropTypes.number,
  //   pv_p_ch2: PropTypes.number,
  //   pv_p_ch3: PropTypes.number,
  //   gp_r: PropTypes.number,
  //   gp_y: PropTypes.number,
  //   gp_b: PropTypes.number,
  //   pv_uv_ch1: PropTypes.bool,
  //   pv_uv_ch2: PropTypes.bool,
  //   pv_uv_ch3: PropTypes.bool,
  //   pv_ov_ch1: PropTypes.bool,
  //   pv_ov_ch2: PropTypes.bool,
  //   pv_ov_ch3: PropTypes.bool,
  //   m_ov_r: PropTypes.bool,
  //   m_ov_y: PropTypes.bool,
  //   m_ov_b: PropTypes.bool,
  //   m_uv_r: PropTypes.bool,
  //   m_uv_y: PropTypes.bool,
  //   m_uv_b: PropTypes.bool,
  //   b_uv_al: PropTypes.bool,
  //   b_uv_co: PropTypes.bool,
  //   i_ol_r: PropTypes.bool,
  //   i_ol_y: PropTypes.bool,
  //   i_ol_b: PropTypes.bool,
  //   i_pv_ool_ch1: PropTypes.bool,
  //   i_pv_ool_ch2: PropTypes.bool,
  //   i_pv_ool_ch3: PropTypes.bool,
  //   fan_fail: PropTypes.bool,
  //   batt_ov: PropTypes.bool,
  //   i_ov_r: PropTypes.bool,
  //   i_ov_y: PropTypes.bool,
  //   i_ov_b: PropTypes.bool,
  //   i_uv_r: PropTypes.bool,
  //   i_uv_y: PropTypes.bool,
  //   i_uv_b: PropTypes.bool,
  //   tcs_f: PropTypes.bool,
  //   sw_off: PropTypes.bool,
  //   createdAt: PropTypes.string,
  // }
};

DeviceDashboard.defaultProps = {

  pvtPoints:{
    datasets:[{
      data:[
              {x:'2021-07-30T00:00:00'},
              {x:'2021-07-30T23:59:59'}]	
      }]
    },
  sysStatus:{
    siteName: "Site: Device Name",
    deviceName: "3-Phase Hybrid Solar Inverter",
    deviceImg: require("./../images/device/3p_def_img.jpg"),
    fwVersion: "Firmware Ver 1.00.00",
    conStat: "N/A",
    lastSync: "N/A",
    osw:false,
    loc_lat:0,
    loc_lon:0,
    mapImg: require("./../images/device/map_mockup.png"),
    temp: "N/A",
    humi: "N/A",
    wind: "N/A",
  },
  
  sysParams: 
  {
    // _id: '610270728c8f2f369ec29f95',
    sno: '1234567890',
    dt_src: null,
    g_ss: 31,
    pvv_ch1: 0,
    pvv_ch2: 0,
    pvv_ch3: 0,
    pvi_ch1: 0,
    pvi_ch2: 0,
    pvi_ch3: 0,
    pv_kwh_t: 0,
    pv_kwh_c: 0,
    bv: 0,
    bi: 0,
    gv_r: 0,
    gv_y: 0,
    gv_b: 0,
    gi_r: 0,
    gi_y: 0,
    gi_b: 0,
    gf_r: 0,
    gf_y: 0,
    gf_b: 0,
    gpf_r: 0,
    gpf_y: 0,
    gpf_b: 0,
    gtp: 0,
    i_kwh_t: 0,
    e_kwh_t: 0,
    im_kwh_c: 0,
    e_kwh_c: 0,
    iv_r: 0,
    iv_y: 0,
    iv_b: 0,
    ii_r: 0,
    ii_y: 0,
    ii_b: 0,
    if_r: 0,
    if_y: 0,
    if_b: 0,
    lpf_r: 0,
    lpf_y: 0,
    lpf_b: 0,
    ip: 0,
    u_kwh_t: 0,
    i_kwh_c: 0,
    batt: 'UNK',
    inv: 'UNK',
    grid: 'UNK',
    pv_p_ch1: 0,
    pv_p_ch2: 0,
    pv_p_ch3: 0,
    gp_r: 0,
    gp_y: 0,
    gp_b: 0,

    pv_uv_ch1: false,
    pv_uv_ch2: false,
    pv_uv_ch3: false,
    pv_ov_ch1: true,
    pv_ov_ch2: true,
    pv_ov_ch3: true,
    m_ov_r: false,
    m_ov_y: false,
    m_ov_b: false,
    m_uv_r: true,
    m_uv_y: true,
    m_uv_b: true,
    b_uv_al: true,
    b_uv_co: false,
    i_ol_r: true,
    i_ol_y: true,
    i_ol_b: true,
    i_pv_ool_ch1: true,
    i_pv_ool_ch2: true,
    i_pv_ool_ch3: true,
    fan_fail: false,
    batt_ov: false,
    i_ov_r: true,
    i_ov_y: true,
    i_ov_b: true,
    i_uv_r: false,
    i_uv_y: false,
    i_uv_b: false,
    tcs_f: false,
    sw_off: false,
    createdAt: '2021-07-29T09:10:10.511Z',
  }
};

export default DeviceDashboard;
