import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  CardFooter,
  Row,
  Col,
  FormSelect
} from "shards-react";

const SystemParameters = ({ title, sysParamData }) => (
  <Card >
    <CardHeader className="border-bottom">
      <h6 className="m-0">{title}</h6>
      <div className="block-handle" />
    </CardHeader>

    <CardBody className="p-0">
      <ListGroup flush className="list-group-small">
        {/* {sysParamData.map((item, idx) => (
          <ListGroupItem className="d-flex px-3">
            <span key={idx} className="text-semibold text-fiord-blue">{item.title}</span>
            <span className="ml-auto text-right text-semibold text-reagent-gray">
              {item.value}
            </span>
          </ListGroupItem>
        ))} */}

        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">PV Voltage (V)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.pvv_ch1.toFixed(1)+', '+sysParamData.pvv_ch2.toFixed(1)+', '+sysParamData.pvv_ch3.toFixed(1)}
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">PV Current (A)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.pvi_ch1.toFixed(1)+', '+sysParamData.pvi_ch2.toFixed(1)+', '+sysParamData.pvi_ch3.toFixed(1)}
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">PV Power (kW)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">          
          {sysParamData.pv_p_ch1.toFixed(1) +', '+sysParamData.pv_p_ch2.toFixed(1)+', '+sysParamData.pv_p_ch3.toFixed(1)}
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">PV Generation Today (kWh)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
            {sysParamData.pv_kwh_t.toFixed(1)}
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">PV Generation Cumulative (kWh)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.pv_kwh_c.toFixed(0)}
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Battery Voltage (V)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.bv.toFixed(1)}
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Battery Current (A)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.bi.toFixed(1)}
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Grid Voltage (V)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.gv_r.toFixed(1)+', '+sysParamData.gv_y.toFixed(1)+', '+sysParamData.gv_b.toFixed(1)}
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Grid Current (A)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.gi_r.toFixed(1)+', '+sysParamData.gi_y.toFixed(1)+', '+sysParamData.gi_b.toFixed(1)}
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Grid Power (kW)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.gp_r.toFixed(1)+', '+sysParamData.gp_y.toFixed(1)+', '+sysParamData.gp_b.toFixed(1)}
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Grid Frequency (Hz)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.gf_r.toFixed(1)+', '+sysParamData.gf_y.toFixed(1)+', '+sysParamData.gf_b.toFixed(1)}
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Grid Power Factor</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.gpf_r.toFixed(2)+', '+sysParamData.gpf_y.toFixed(2)+', '+sysParamData.gpf_b.toFixed(2)} 
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Total Grid Power (kW)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.gtp.toFixed(1)} 
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Today Import (kWh)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.i_kwh_t.toFixed(1)} 
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Today Export (kWh)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.e_kwh_t.toFixed(1)} 
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Cumulative Import (kWh)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.im_kwh_c.toFixed(0)} 
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Cumulative Export (kWh)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.e_kwh_c.toFixed(0)} 
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Inverter Voltage (V)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.iv_r.toFixed(1)+', '+sysParamData.iv_y.toFixed(1)+', '+sysParamData.iv_b.toFixed(1)} 
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Inverter Current (A)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.ii_r.toFixed(1)+', '+sysParamData.ii_y.toFixed(1)+', '+sysParamData.ii_b.toFixed(1)} 
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Inverter Frequency (Hz)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.if_r.toFixed(1)+', '+sysParamData.if_y.toFixed(1)+', '+sysParamData.if_b.toFixed(1)} 
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Inverter Power (kW)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.ip.toFixed(1)} 
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Load Power Factor</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.lpf_r.toFixed(1)+', '+sysParamData.lpf_y.toFixed(1)+', '+sysParamData.lpf_b.toFixed(1)} 
          </span>
        </ListGroupItem>
        {/* <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Inverter Total Power (kW)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.ip}
          </span>
        </ListGroupItem> */}
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Total Used Energy (kWh)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.u_kwh_t.toFixed(1)}
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex px-3">
          <span className="text-semibold text-fiord-blue">Inverter Cumulative Energy (kW)</span>
          <span className="ml-auto text-right text-semibold text-reagent-gray">
          {sysParamData.i_kwh_c.toFixed(0)}
          </span>
        </ListGroupItem>
      </ListGroup>
    </CardBody>
  </Card>
);

SystemParameters.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string,
  /**
   * The referral data.
   */
  sysParamData: 
  {
    // _id: PropTypes.string,
    sno: PropTypes.string,
    dt_src: PropTypes.string,
    g_ss: PropTypes.number,
    pvv_ch1: PropTypes.number,
    pvv_ch2: PropTypes.number,
    pvv_ch3: PropTypes.number,
    pvi_ch1: PropTypes.number,
    pvi_ch2: PropTypes.number,
    pvi_ch3: PropTypes.number,
    pv_kwh_t: PropTypes.number,
    pv_kwh_c: PropTypes.number,
    bv: PropTypes.number,
    bi: PropTypes.number,
    gv_r: PropTypes.number,
    gv_y: PropTypes.number,
    gv_b: PropTypes.number,
    gi_r: PropTypes.number,
    gi_y: PropTypes.number,
    gi_b: PropTypes.number,
    gf_r: PropTypes.number,
    gf_y: PropTypes.number,
    gf_b: PropTypes.number,
    gpf_r: PropTypes.number,
    gpf_y: PropTypes.number,
    gpf_b: PropTypes.number,
    gtp: PropTypes.number,
    i_kwh_t: PropTypes.number,
    e_kwh_t: PropTypes.number,
    im_kwh_c: PropTypes.number,
    e_kwh_c: PropTypes.number,
    iv_r: PropTypes.number,
    iv_y: PropTypes.number,
    iv_b: PropTypes.number,
    ii_r: PropTypes.number,
    ii_y: PropTypes.number,
    ii_b: PropTypes.number,
    if_r: PropTypes.number,
    if_y: PropTypes.number,
    if_b: PropTypes.number,
    lpf_r: PropTypes.number,
    lpf_y: PropTypes.number,
    lpf_b: PropTypes.number,
    ip: PropTypes.number,
    u_kwh_t: PropTypes.number,
    i_kwh_c: PropTypes.number,
    batt: PropTypes.string,
    inv: PropTypes.string,
    grid: PropTypes.string,
    pv_p_ch1: PropTypes.number,
    pv_p_ch2: PropTypes.number,
    pv_p_ch3: PropTypes.number,
    gp_r: PropTypes.number,
    gp_y: PropTypes.number,
    gp_b: PropTypes.number,
    pv_uv_ch1: PropTypes.bool,
    pv_uv_ch2: PropTypes.bool,
    pv_uv_ch3: PropTypes.bool,
    pv_ov_ch1: PropTypes.bool,
    pv_ov_ch2: PropTypes.bool,
    pv_ov_ch3: PropTypes.bool,
    m_ov_r: PropTypes.bool,
    m_ov_y: PropTypes.bool,
    m_ov_b: PropTypes.bool,
    m_uv_r: PropTypes.bool,
    m_uv_y: PropTypes.bool,
    m_uv_b: PropTypes.bool,
    b_uv_al: PropTypes.bool,
    b_uv_co: PropTypes.bool,
    i_ol_r: PropTypes.bool,
    i_ol_y: PropTypes.bool,
    i_ol_b: PropTypes.bool,
    i_pv_ool_ch1: PropTypes.bool,
    i_pv_ool_ch2: PropTypes.bool,
    i_pv_ool_ch3: PropTypes.bool,
    fan_fail: PropTypes.bool,
    batt_ov: PropTypes.bool,
    i_ov_r: PropTypes.bool,
    i_ov_y: PropTypes.bool,
    i_ov_b: PropTypes.bool,
    i_uv_r: PropTypes.bool,
    i_uv_y: PropTypes.bool,
    i_uv_b: PropTypes.bool,
    tcs_f: PropTypes.bool,
    sw_off: PropTypes.bool,
    createdAt: PropTypes.string,
  }
};

SystemParameters.defaultProps = {
  title: "System Parameters",
  sysParamData: 
  {
    // _id: '610270728c8f2f369ec29f95',
    sno: '1234567890',
    dt_src: null,
    g_ss: 31,
    pvv_ch1: 0,
    pvv_ch2: 0,
    pvv_ch3: 0,
    pvi_ch1: 0,
    pvi_ch2: 0,
    pvi_ch3: 0,
    pv_kwh_t: 0,
    pv_kwh_c: 0,
    bv: 0,
    bi: 0,
    gv_r: 0,
    gv_y: 0,
    gv_b: 0,
    gi_r: 0,
    gi_y: 0,
    gi_b: 0,
    gf_r: 0,
    gf_y: 0,
    gf_b: 0,
    gpf_r: 0,
    gpf_y: 0,
    gpf_b: 0,
    gtp: 0,
    i_kwh_t: 0,
    e_kwh_t: 0,
    im_kwh_c: 0,
    e_kwh_c: 0,
    iv_r: 0,
    iv_y: 0,
    iv_b: 0,
    ii_r: 0,
    ii_y: 0,
    ii_b: 0,
    if_r: 0,
    if_y: 0,
    if_b: 0,
    lpf_r: 0,
    lpf_y: 0,
    lpf_b: 0,
    ip: 0,
    u_kwh_t: 0,
    i_kwh_c: 0,
    batt: 'UNK',
    inv: 'UNK',
    grid: 'UNK',
    pv_p_ch1: 0,
    pv_p_ch2: 0,
    pv_p_ch3: 0,
    gp_r: 0,
    gp_y: 0,
    gp_b: 0,
    pv_uv_ch1: false,
    pv_uv_ch2: false,
    pv_uv_ch3: false,
    pv_ov_ch1: true,
    pv_ov_ch2: true,
    pv_ov_ch3: true,
    m_ov_r: false,
    m_ov_y: false,
    m_ov_b: false,
    m_uv_r: true,
    m_uv_y: true,
    m_uv_b: true,
    b_uv_al: true,
    b_uv_co: false,
    i_ol_r: true,
    i_ol_y: true,
    i_ol_b: true,
    i_pv_ool_ch1: true,
    i_pv_ool_ch2: true,
    i_pv_ool_ch3: true,
    fan_fail: false,
    batt_ov: false,
    i_ov_r: true,
    i_ov_y: true,
    i_ov_b: true,
    i_uv_r: false,
    i_uv_y: false,
    i_uv_b: false,
    tcs_f: false,
    sw_off: false,
    createdAt: '2021-07-29T09:10:10.511Z',
  }
};

export default SystemParameters;
