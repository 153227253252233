import React from "react";
import PropTypes from "prop-types";
import {
  Container, Row, Col, Card, CardHeader, CardBody, Button, ButtonGroup, ListGroupItem, ListGroup,
  FormInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";

import PageTitle from "./../components/common/PageTitle";
import SmallStats from "./../components/common/SmallStats";
import TodayGraphs from "../components/blog/TodayGraphs";
import CumulativeGraphs from "../components/blog/CumulativeGraphs";
import SystemStatus from "./../components/common/SystemStatus";
import SettingSimple from "./../components/common/SettingSimple";
import RYBStats from "./../components/common/RYBStats";
import SmallButtons from "../components/components-overview/SmallButtons";

import DeviceDetails from "../components/user-profile-lite/UserDetails";

import NumericInput from 'react-numeric-input';

const DeviceDashboard = ({ rYBStats, smallStats_pv, smallStats_inv, smallStats_batt }) => (
  <Container fluid className="main-content-container px-4">
    {/* Page Header */}
    <Row noGutters className="page-header py-4">
      <PageTitle title="Device: Device Name" subtitle="Hi User Name" className="text-sm-left mb-3" />
      {/* <ButtonGroup className="mb-3">
        <Button theme="primary">SIMPLE</Button>
        <Button theme="white">ADVANCE</Button>
      </ButtonGroup> */}
    </Row>
    <Row>
      {/* Device Details */}
      <Col lg="3" md="12" sm="12" className="mb-4">
        <DeviceDetails />
      </Col>
      <Col lg="9" md="12" sm="12" className="mb-4">
        <Row >
          <Col lg="4" md="12" sm="12" className="mb-4">
            {rYBStats.map((stats, idx) => (
              <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
                <RYBStats
                  id={`small-stats-${idx}`}
                  variation="1"
                  chartData={stats.datasets}
                  chartLabels={stats.chartLabels}
                  label={stats.label}
                  value_r={stats.value_r}
                  value_y={stats.value_y}
                  value_b={stats.value_b}
                />
              </Col>
            ))}
            <Col lg="12" md="12" sm="12" className="mb-4">
              <SystemStatus />
            </Col>
          </Col>
          <Col lg="8" md="12" sm="12" className="mb-4">
            <Col className="mb-4 p-0">
              <TodayGraphs />
            </Col>
            <Row>
              {smallStats_pv.map((stats, idx) => (
                <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
                  <SmallStats
                    id={`small-stats-${idx}`}
                    variation="1"
                    chartData={stats.datasets}
                    chartLabels={stats.chartLabels}
                    label={stats.label}
                    value={stats.value}
                  />
                </Col>
              ))}
            </Row>
            <Row>
              {smallStats_inv.map((stats, idx) => (
                <Col className="col-lg mb-4" key={idx} {...stats.attrs}>
                  <SmallStats
                    id={`small-stats-${idx}`}
                    variation="1"
                    chartData={stats.datasets}
                    chartLabels={stats.chartLabels}
                    label={stats.label}
                    value={stats.value}
                  />
                </Col>
              ))}
            </Row>
            <Row>
              <Col lg="12" md="12" sm="12" className="mb-4">
                {/* <SettingSimple /> */}
                <Card >
                  <CardHeader className="border-bottom">
                    <h6 className="m-0">System Settings</h6>
                    <div className="block-handle" />
                  </CardHeader>

                  <CardBody className="p-0">
                    <ListGroup flush className="list-group-small">
                      <ListGroupItem className="d-flex px-3">
                        <span className="text-semibold text-fiord-blue">System Mode</span>
                        <span className="ml-auto text-right text-semibold text-reagent-gray">
                          <ButtonGroup className="mb-0">
                            <Button theme="primary">HYBRID</Button>
                            <Button theme="white">SBG</Button>
                            <Button theme="white">SGB</Button>
                            <Button theme="white">GSB</Button>
                          </ButtonGroup>
                        </span>
                      </ListGroupItem>
                      <ListGroupItem className="d-flex px-3">
                        <span className="text-semibold text-fiord-blue">Battery Type</span>
                        <span className="ml-auto text-right text-semibold text-reagent-gray">
                          <ButtonGroup className="mb-0">
                            <Button theme="primary">VRLA</Button>
                            <Button theme="white">LM</Button>
                            <Button theme="white">Li-Ion</Button>
                          </ButtonGroup>
                        </span>
                      </ListGroupItem>
                      <ListGroupItem className="d-flex px-3">
                        <span className="text-semibold text-fiord-blue">Battery Capacity</span>
                        <span className="ml-auto text-right text-semibold text-reagent-gray">
                          {/* <NumericInput min={100} max={600} value={300} step={2}/> */}
                          <InputGroup className="mb-0">
                            <FormInput value="1000" onChange={() => { }} />
                            <InputGroupAddon type="append">
                              <InputGroupText>Volts</InputGroupText>
                            </InputGroupAddon>
                          </InputGroup>
                        </span>
                      </ListGroupItem>
                      <ListGroupItem className="d-flex px-3">
                        <span className="text-semibold text-fiord-blue">Save</span>
                        <span className="ml-auto text-right text-semibold text-reagent-gray">
                          <Button outline theme="primary" className="mb-2 mr-1">
                            Save
                          </Button>
                        </span>
                      </ListGroupItem>
                    </ListGroup>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  </Container>
);

DeviceDashboard.propTypes = {
  /**
   * The small stats dataset.
   */
  rYBStats: PropTypes.array,
  smallStats_pv: PropTypes.array,
  smallStats_inv: PropTypes.array
};

DeviceDashboard.defaultProps = {
  rYBStats: [
    {
      label: "GRID VOLTAGE (V)",
      value_r: "550",
      value_y: "550",
      value_b: "550",
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        },
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        },
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    },
    {
      label: "PV VOLTAGE (V)",
      value_r: "550",
      value_y: "550",
      value_b: "550",
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        },
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        },
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    },
    {
      label: "INVERTER VOLTAGE (V)",
      value_r: "550",
      value_y: "550",
      value_b: "550",
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        },
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        },
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    },
    {
      label: "INVERTER CURRENT (A)",
      value: "182",
      percentage: "12.4",
      increase: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "6", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(0, 184, 216, 0.1)",
          borderColor: "rgb(0, 184, 216)",
          data: [1, 2, 1, 3, 5, 4, 7]
        },
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        },
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    }
  ],
  smallStats_pv: [
    {
      label: "PV POWER (kW)",
      value: "8,147",
      percentage: "3.8%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        }
      ]
    },
    {
      label: "BATTERY VOLTAGE (V)",
      value: "8,147",
      percentage: "3.8%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,180,0,0.1)",
          borderColor: "rgb(255,180,0)",
          data: [2, 3, 3, 3, 4, 3, 3]
        }
      ]
    },
    {
      label: "BATTERY CURRENT (A)",
      value: "29",
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    }
  ],
  smallStats_inv: [
    {
      label: "TODAY PV GEN (kWh)",
      value: "29",
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [0]
        }
      ]
    },
    {
      label: "GRID POWER (kW)",
      value: "29",
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    },
    {
      label: "INVERTER POWER (kW)",
      value: "29",
      percentage: "2.71%",
      increase: false,
      decrease: true,
      chartLabels: [null, null, null, null, null, null, null],
      attrs: { md: "4", sm: "6" },
      datasets: [
        {
          label: "Today",
          fill: "start",
          borderWidth: 1.5,
          backgroundColor: "rgba(255,65,105,0.1)",
          borderColor: "rgb(255,65,105)",
          data: [1, 7, 1, 3, 1, 4, 8]
        }
      ]
    }
  ]
};

export default DeviceDashboard;
