import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  ListGroup,
  ListGroupItem,
  Progress,
  FormCheckbox
} from "shards-react";

const UserDetails = ({ userDetails }) => (
  <Card small className="mb-4 pt-3">
    <CardHeader className="border-bottom text-center">
      <div className="mb-3 mx-auto">
        <img
          className="rounded"
          src={userDetails.avatar}
          alt={userDetails.name}
          width="180"
        />
      </div>
      <h4 className="mb-0">{userDetails.name}</h4>
      <span className="text d-block mb-0">{userDetails.jobTitle}</span>
      <span className="text-muted d-block mb-0">{userDetails.fwVersion}</span>
      {/* <Button pill outline size="sm" className="mb-2">
        <i className="material-icons mr-1">info</i> Details
      </Button> */}
    </CardHeader>
    <ListGroup flush>
      <ListGroupItem className="d-flex px-3">
        <span className="text-semibold text-fiord-blue">Connectivity Status</span>
        <span className="ml-auto text-right text-semibold text-reagent-gray">
          {userDetails.conStat}
        </span>
      </ListGroupItem>
      <ListGroupItem className="d-flex px-3">
        <span className="text-semibold text-fiord-blue">Last Sync</span>
        <span className="ml-auto text-right text-semibold text-reagent-gray">
          {userDetails.lastSync}
        </span>
      </ListGroupItem>
      <ListGroupItem className="d-flex px-3">
        <span className="text-semibold text-fiord-blue">Output Switch</span>
        <span className="ml-auto text-right text-semibold text-reagent-gray">
        <FormCheckbox toggle small>
        OFF
      </FormCheckbox>
        </span>
      </ListGroupItem>
      <ListGroupItem className="p-3">
        <strong className="text-muted d-block mb-2">
          Location
        </strong>
        <div className="mb-0 mx-auto">
          <img
            class="mx-auto d-block rounded img-fluid"
            src={userDetails.mapImg}
          />
        </div>
      </ListGroupItem>
      <ListGroupItem className="d-flex px-3">
        <span className="text-semibold text-fiord-blue">Temperature</span>
        <span className="ml-auto text-right text-semibold text-reagent-gray">
          {userDetails.temp}
        </span>
      </ListGroupItem>
      <ListGroupItem className="d-flex px-3">
        <span className="text-semibold text-fiord-blue">Humidity</span>
        <span className="ml-auto text-right text-semibold text-reagent-gray">
          {userDetails.humi}
        </span>
      </ListGroupItem>
      <ListGroupItem className="d-flex px-3">
        <span className="text-semibold text-fiord-blue">Wind Speed / Dir</span>
        <span className="ml-auto text-right text-semibold text-reagent-gray">
          {userDetails.wind}
        </span>
      </ListGroupItem>
    </ListGroup>
  </Card>
);

UserDetails.propTypes = {
  /**
   * The user details object.
   */
  userDetails: PropTypes.object
};

UserDetails.defaultProps = {
  userDetails: {
    siteName: "Site: Device Name",
    avatar: require("./../../images/device/3p_def_img.jpg"),
    deviceName: "3-Phase Hybrid Solar Inverter",
    fwVersion: "Firmware Ver 1.00.00",
    conStat: "Online",
    lastSync: "11:11:12 12-07-2021",
    mapImg: require("./../../images/device/map_mockup.png"),
    temp: "35 °C",
    humi: "78%",
    wind: "1.9kt / 311°",
  }
};

export default UserDetails;
